//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import UMUM from "../../library/umum";


export default {
  data() {
    return {

      form : {
        id : '',
        tahun : '',
        bulan : '',
        status : '',
      },
      profile : {
        id: "",
        nip: "",
        penilai: "",
        atasan_penilai: "",

        bio_nama : '',
        bio_gelar_belakang : '',
        bio_gelar_depan : '',
        bio_gol : '',
        bio_pangkat : '',
        bio_ruang : '',
        bio_jabatan : '',
        bio_unit_kerja : '',

        penilai_nama : '',
        penilai_gelar_belakang : '',
        penilai_gelar_depan : '',
        penilai_pangkat : '',
        penilai_gol : '',
        penilai_ruang : '',
        penilai_jabatan : '',
        penilai_unit_kerja : '',
        metode_absen : '',

        ap_nama : '',
        ap_gelar_belakang : '',
        ap_gelar_depan : '',
        ap_pangkat : '',
        ap_gol : '',
        ap_ruang : '',
        ap_jabatan : '',
        ap_unit_kerja : '',
      },
     
      list_data : [],

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      btn_add: false,
      rata_rata : 0,
      jml : 0,

      UMUM : UMUM,



      list_rerata : 0,

    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_kinerja_worksheet + "lap_harian", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              bulan: this.form.bulan,
              tahun: this.form.tahun,
              nip : this.profile.nip,
              metode_absen : this.profile.metode_absen,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data;
              this.$store.commit("hideLoading");
              console.log(res_data);


              this.rata_ratax();

              
              var arr = [];

              res_data.forEach(element => {
                const datax = element.data 
                // console.log('element');
                // console.log(datax.length);

                var nilai = 0
                var rerata = 0

                datax.forEach(element2 => {
                  nilai = nilai + element2.score
                });

                rerata = nilai/datax.length
                // console.log(rerata);

                arr.push(rerata)

              });


              this.list_rerata = arr

              console.log(this.list_rerata);

              this.jml =  eval(this.list_rerata.join('+'))

          





      });
    },


    addData : function(number) {
      fetch(this.$store.state.url.URL_MasterKategori + "Add", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      fetch(this.$store.state.url.URL_MasterKategori + "editData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(this.form)
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_MasterKategori + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    
    selectData : function(data){
        this.form.id = data.id;
        this.form.uraian = data.uraian;
        this.form.keterangan = data.keterangan;
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


        rata_ratax : function(){
          // let dt = this.list_data
          // var data = []
          // dt.forEach(element => {
          //   element.data.forEach(element1 => {
          //     data.push(element1.score)
          //   });            
          // });



          // const rata_rata = eval(data.join('+'))/data.length
          // // console.log(rata_rata);
          // this.rata_rata = rata_rata

          // this.jml = eval(data.join('+'))
        },

        getProfile: function() {
        //   this.cek_load_data = true;
            fetch(this.$store.state.url.URL_kinerja_asn + "view", {
            method: "POST",
            headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                nip : this.profile.nip
            })
            })
            .then(res => res.json())
            .then(res_data => {
                console.log(res_data)

                var data = res_data[0]




                this.profile.id = data.id;
                this.profile.nip= data.nip;
                this.profile.penilai= data.penilai;
                this.profile.atasan_penilai= data.atasan_penilai;

                this.profile.bio_nama = data.bio_nama;
                this.profile.bio_gelar_belakang = data.bio_gelar_belakang;
                this.profile.bio_gelar_depan = data.bio_gelar_depan;
                this.profile.bio_gol = data.bio_gol;
                this.profile.bio_pangkat = data.bio_pangkat;
                this.profile.bio_ruang = data.bio_ruang;
                this.profile.bio_jabatan = data.bio_jabatan;
                this.profile.bio_unit_kerja = data.bio_unit_kerja;

                this.profile.penilai_nama = data.penilai_nama;
                this.profile.penilai_gelar_belakang = data.penilai_gelar_belakang;
                this.profile.penilai_gelar_depan = data.penilai_gelar_depan;
                this.profile.penilai_pangkat = data.penilai_pangkat;
                this.profile.penilai_gol = data.penilai_gol;
                this.profile.penilai_ruang = data.penilai_ruang;
                this.profile.penilai_jabatan = data.penilai_jabatan;
                this.profile.penilai_unit_kerja = data.penilai_unit_kerja;

                this.profile.ap_nama = data.ap_nama;
                this.profile.ap_gelar_belakang = data.ap_gelar_belakang;
                this.profile.ap_gelar_depan = data.ap_gelar_depan;
                this.profile.ap_pangkat = data.ap_pangkat;
                this.profile.ap_gol = data.ap_gol;
                this.profile.ap_ruang = data.ap_ruang;
                this.profile.ap_jabatan = data.ap_jabatan;
                this.profile.ap_unit_kerja = data.ap_unit_kerja;
                // this.list_data = res_data.data;
                // this.page_last = res_data.jml_data;
                // this.selectData(res_data.data[0])
            //   this.cek_load_data = false;
            });
        },

        print () {
          // Pass the element id here
          this.$htmlToPaper('printMe');
        }


    // ====================================== PAGINATE ====================================







  },

  mounted () {
    const d = new Date();
    this.form.bulan = d.getMonth()+1;
    this.form.tahun = d.getFullYear();

    console.log(this.form.tahun);

    var get_profile = JSON.parse(localStorage.profile);
    this.profile.nip = get_profile.profile.nip;

    // console.log("METODE DI PANGGIL");
    this.profile.metode_absen = get_profile.profile.metode_absen;
    // console.log("METODE DI PANGGIL");

    this.getProfile();
    this.getView();
  },
}
